/* eslint-disable object-curly-newline */
/* eslint-disable react/no-array-index-key */
/* eslint-disable react/prop-types */
import React from "react";
import { Section } from "../../sdk/Layout";
import InternalLink from "../InternalLink";
import { Container, Cards, Card } from "./style";

function BrandCard({ brand }) {
	const { title, paragraph, image, logo, link } = brand;

	return (
		<Card>
			<InternalLink to={link}>
				<picture>
					<img src={image.img} alt={title} />
				</picture>
				<div className="info">
					<img src={logo.img} alt={title} />
					{/* <figure>
						<img src={logo.img} alt={title} />
					</figure>
					<p>{paragraph}</p> */}
				</div>
			</InternalLink>
		</Card>
	);
}

function BrandsNew({ config }) {
	if (!config?.length) return null;

	const { title, brands } = config[0];

	return (
		<Section>
			<Container>
				<h2>{title}</h2>
				<Cards>
					{brands.map((brand, i) => <BrandCard key={`brand-new-card-${i}`} brand={brand} />)}
				</Cards>
			</Container>
		</Section>
	);
}

export default BrandsNew;
