import styled from "styled-components";
import { Container as ContainerEx } from "../../sdk/Layout";

export const Container = styled(ContainerEx)`
	h2 {
		font-size: 28px;
		font-weight: 800;
		text-transform: uppercase;
		margin-bottom: 16px;
	}
	h2, h3, p {
		color: ${(props) => props.theme.textDefault};
		text-align: center;
	}
`;

export const Cards = styled.div`
	display: grid;
	grid-template-columns: repeat(4, 1fr);
	gap: 16px;

	@media (max-width: 768px) {
		grid-template-columns: repeat(2, 1fr);
	}
`;

export const Card = styled.div`
	display: flex;
	a {
		display: flex;
		flex-direction: column;
		flex-grow: 1;
	}
	picture img {
		width: 100%;
		height: 100%;
		object-fit: cover;
		transition: all 1.2s cubic-bezier(0.165, 0.84, 0.44, 1);
	}
	.info {
		position: relative;
		padding: 20px 0;
		border: 1px solid ${(props) => props.theme.primary100};
		background-color: #f8f8f8;
		display: flex;
		justify-content: center;
		align-items: center;
		// h3 {
		// 	font-size: 24px;
		// 	font-weight: 600;
		// 	margin-bottom: 8px;
		// }
		img {
			width: 160px;
			//height: 100%;
			object-fit: cover;
		}
	}
	
	:hover {
		picture img, .info {
			opacity: 0.8;
		}
	}
`;
